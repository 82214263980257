export enum ReportType {
  None = 0,
  TDC = 10,
  ICP = 20,
  TDC_ICP = 30,
  EMSP = 40,
  All = 100,
}

export enum AccessLevel {
  Global = 100,
  CompensationCommittee = 90,
  RMD = 65,
  SrMD = 55,
  MD = 45,
  SolutionMD = 35,
  IndustryMD = 25,
  CountryMarketLeader = 15,
  GlobalIndustryLeader = 5,
  None = 0,
}

export enum LeadershipRoles {
  SolutionLeader,
  IndustryLeader,
  RegionalMD,
  MarketLeader,
  GSAMLead,
  SeniorOperationsVP,
}

export enum PartTimeAgreement {
  Yes,
  No,
}

export enum RiskEvaluation {
  GoldStar,
  NeedsImprovement,
  None,
}

export enum AuditAction {
  ADD,
  UPDATE,
  DELETE,
  COMP_CREATED,
  COMP_SENT,
  COMP_UPDATED,
  COMP_APPROVED,
  COMP_REJECTED,
}

export enum Currency {
  USD = 'USD',
  LOCAL = 'Local',
}

export enum FilterInputType {
  StringInput,
  NumberInput,
  Autocomplete,
  Select,
  Multiselect,
}
