import { Injectable, WritableSignal, signal } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable, Subject, concat, map } from 'rxjs';
import { Dashboard } from 'app/core/views/dashboard.model';
import { CurrentUser } from 'app/core/views/current-user.model';
import { AccessLevel } from 'app/core/enums';
import { AuditLog } from 'app/core/views/auditlog.model';
import { Region } from 'app/core/views/region.model';
import { ErrorHandlingService } from '../error-handling/error-handling.service';
import { compcommunicatorreport } from '../../../core/views/compcommunicatorreport.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  currentUser = signal<CurrentUser | null>(null);
  regions = signal<Region[]>([]);
  regionsParam = signal('');
  selectedRegions = signal<Region[]>([]);
  selectedCountries = signal<string[]>([]);
  selectedCountry = signal('');
  selectedYear = signal(0);
  selectedCurrency = signal('');
  currentAccess = signal<AccessLevel>(AccessLevel.None);
  isMsalAuthenticated = signal(false);
  isAPIAuthenticated = signal(false);
  isFullyLoaded = false;
  notificationCount = signal(0);
  userRegionKeys!: string[];
  userCountrys!: string[];
  workflowStage = signal(0);

  private filterChanged = new Subject<boolean>();
  /** Only use this subject for triggering filter changes inside of component in the router-oulet */
  getFilterChangedSubject$ = this.filterChanged.asObservable();
  /** Generic trigger for applying filters for the route components to trigger an api call and page update */
  sendFilterChange(): void {
    this.filterChanged.next(true);
  }

  constructor(private _apiService: ApiService, private _errorHandlingService: ErrorHandlingService) {}

  //#region API Calls
  getDashboardData(country?: string, regionKey?: string, currency?: string): Observable<Dashboard> {
    let params = new Map<string, string>();
    if (country) params.set('country', country);
    if (regionKey) params.set('regionKey', regionKey);
    if (currency) params.set('currency', currency);
    return this._apiService.httpGet<Dashboard>('Users/Dashboard', params);
  }

  getAuditLogs(): Observable<AuditLog[]> {
    return this._apiService.httpGet<AuditLog[]>('Users/AuditLogs');
  }
  //#endregion

  getCurrentUser(): void {
    const user = this.currentUser();
    if (user) return;

    const url = 'authorization/current';
    this._apiService.httpGet<CurrentUser>(url).subscribe({
      next: (user: CurrentUser) => {

        this.currentUser.update(() => user);
        this.currentAccess.update(() => user.role);

        user.regions.forEach(region => {
          region.hasCountries = region.countries.length > 0 ? true : false;
          if (region.hasCountries) region.countries = region.countries.sort((a, b) => a.localeCompare(b));
        });

        this.selectedCurrency.update(() => 'Local');
        this.regions.set(user.regions);

        this.changeRegions(user.regions);
        const getAllCountries = this.getDistinctCountriesInRegion(user.regions);

        if (getAllCountries.length > 0) {
          this.changeCountries(getAllCountries);
        } else {
          this.changeCountries([user.country]);
        }

        this.isAPIAuthenticated.update(() => true);
        if (user.role === AccessLevel.Global) {
          setTimeout(() => {
            this.getAuditLogs().subscribe(logs => {
              if (logs) this.notificationCount.update(() => logs.length);
            });
          }, 4000)
        }
        this.isFullyLoaded = true;
      },
      error: error => {
        console.error('Error fetching current user', error.error ? error.error.error : error);
        this._errorHandlingService.handleError(error, 5000);
        const errorStatus = error.status ? error.status : 0;
        const unauthorizedErrors = [401, 403];
        this.isAPIAuthenticated.update(() => !unauthorizedErrors.includes(errorStatus));
        this.isFullyLoaded = true;
      },
    });
  }

  changeRegions(regions: Region[]) {
    // this.selectedRegions.update(() => regions);
    let regionKeys = regions.map(region => region.regionKey);
    let concatenatedRegionKeys = '';
    regionKeys.forEach((regionKey, index) => {
      concatenatedRegionKeys += regionKey + (index < regionKeys.length - 1 ? ',' : '');
    });

    this.regionsParam.update(() => concatenatedRegionKeys);
    this.selectedRegions.update(() => regions);
  }

  changeCountries(countries: string[]) {
    let concatenatedCountries = '';
    countries.forEach((country, index) => {
      if (index === countries.length - 1) {
        concatenatedCountries += country;
      } else {
        concatenatedCountries += country + ',';
      }
    });

    this.selectedCountry.update(() => concatenatedCountries);
    this.selectedCountries.update(() => countries);
  }

  updateCurrency(currency: string): void {
    this.selectedCurrency.update(() => currency);
  }

  getDistinctCountriesInRegion(regions: Region[]): string[] {
    const countrySet = new Set<string>();

    regions.forEach(region => {
      region.countries.forEach(country => {
        countrySet.add(country);
      });
    });

    return Array.from(countrySet);
  }

  exportCompCommReport(): Observable<compcommunicatorreport[]> {
    return this._apiService.httpGet<compcommunicatorreport[]>('Reports/GetCompCommunicatorReport');
  }
}
